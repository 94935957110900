/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Helmet }from "react-helmet"



import Header from "./header"
import Footer from "./footer"
import "./layout.css"

import styled from 'styled-components'
import Theme from './theme'


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  padding: 1rem;
  
  background-color: ${props => props.theme.dark};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border: solid 10px ${props => props.theme.ultralight};
`

const Content = styled.div`
  flex-grow: 1;
  background-color: ${props => props.theme.medium};
`

  return (
    <Theme>
    <Container>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ukr0nco.css"/>
      </Helmet>
      <Content>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
      </Content>
      {/*<Footer />*/}
    </Container>
    </Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
