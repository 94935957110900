import React, {useState, useEffect} from "react";
import { ThemeProvider } from "styled-components";


const colours = {
    green: {
        ultralight: 'rgba(104,224,34,1)',
        light: 'rgba(104,224,34,1)',
        medium: 'rgba(62,134,34,1)',
        dark: 'rgba(16,35,12,1)',
    },
    
    blue: {
        ultralight: 'rgba(177,252,252,1)',
        light: 'rgba(32,102,249,1)',
        medium: 'rgba(25,75,221,1)',
        dark: 'rgba(3,7,43,1)',
    },
    
    orange: {
        ultralight: 'rgba(254,248,201,1)',
        light: 'rgba(32,102,249,1)',
        medium: 'rgba(231,100,40,1)',
        dark: 'rgba(28,5,11,1)',
    }
}

const themeOptions = ['green', 'blue', 'orange']


const Theme = ({ children }) => {
    let count = 0;
    const [currentTheme, setCurrentTheme] = useState('green');
    
    useEffect(() => {
        const intervalId = setInterval(function(){ 
            setCurrentTheme(themeOptions[count])
            count === (themeOptions.length - 1) ? count = 0 : count++
        
        }, 5000);
        
        return () => clearInterval(intervalId);
        
    }, []);
    
    return (
        <ThemeProvider theme={colours[currentTheme]}>{children}</ThemeProvider>
    )
 
};

export default Theme;