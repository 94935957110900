import { Link } from "gatsby";
import React from "react";

import styled from 'styled-components'

const FooterContainer = styled.footer`
    position: absolute;
    bottom: 1rem;
    color: white;
    right: 1rem;
    color: ${props=>props.theme.ultralight};
    font-family: roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    
    a {
        color: ${props=>props.theme.ultralight};
        text-decoration: none;
    }

`

const Span = styled.span`
    font-family: bungee, sans-serif;
    font-weight: 800;
`

const Footer = () => {
    return(
        <FooterContainer>
          © {new Date().getFullYear()} 
          {` `}
          <Span>La Boom</Span>
        </FooterContainer>
    )
}

export default Footer;

 